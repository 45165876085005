const UnitType = {
  TYPE_N: { code: 'n', name: 'unit.newton', symbol: 'N' },
  TYPE_MN: { code: 'mn', name: 'unit.milinewton', symbol: 'mN' },
  TYPE_LBF: { code: 'lbf', name: 'unit.pound_force', symbol: 'lbf' },
  TYPE_KN: { code: 'kn', name: 'unit.kilonewton', symbol: 'KN' },
  TYPE_KGF: { code: 'kgf', name: 'unit.kilogram_force', symbol: 'Kgf' },
  TYPE_GF: { code: 'gf', name: 'unit.gram_force', symbol: 'gf' },
  TYPE_DAN: { code: 'dan', name: 'unit.decanewton', symbol: 'daN' },
  TYPE_KN_DIV_M: {
    code: 'kn/m',
    name: 'unit.kilonewton_per_meter',
    symbol: 'KN/m'
  },
  TYPE_S: { code: 's', name: 'unit.second', symbol: 's' },
  TYPE_MIN: { code: 'min', name: 'unit.minute', symbol: 'min' },
  TYPE_H: { code: 'h', name: 'unit.hour', symbol: 'h' },
  TYPE_MS: { code: 'ms', name: 'unit.millisecond', symbol: 'ms' },
  TYPE_MM_S: {
    code: 'mm/s',
    name: 'unit.milimeter_per_second',
    symbol: 'mm/s'
  },
  TYPE_M_S: { code: 'm/s', name: 'unit.meter_per_second', symbol: 'm/s' },
  TYPE_IN_MIN: {
    code: 'in/min',
    name: 'unit.inch_per_minute',
    symbol: 'in/min'
  },
  TYPE_KM_H: { code: 'km/h', name: 'unit.kilometer_per_hour', symbol: 'Km/h' },
  TYPE_MM_MIN: {
    code: 'mm/min',
    name: 'unit.milimeter_per_minute',
    symbol: 'mm/min'
  },
  TYPE_M_MIN: {
    code: 'm/min',
    name: 'unit.meter_per_minute',
    symbol: 'm/min'
  },
  TYPE_M_DIV_H: {
    code: 'm/h',
    name: 'unit.meter_per_hour',
    symbol: 'm/h'
  },
  TYPE_MM: { code: 'mm', name: 'unit.milimeter', symbol: 'mm' },
  TYPE_MICROMETER: { code: 'µm', name: 'unit.micrometer', symbol: 'µm' },
  TYPE_M: { code: 'm', name: 'unit.meter', symbol: 'm' },
  TYPE_CM: { code: 'cm', name: 'unit.centimeter', symbol: 'cm' },
  TYPE_FT: { code: 'ft', name: 'unit.foot', symbol: 'ft' },
  TYPE_IN: { code: 'in', name: 'unit.inch', symbol: 'in' },
  TYPE_KM: { code: 'km', name: 'unit.kilometer', symbol: 'km' },
  TYPE_BRILLO: { code: 'brillo', name: 'unit.brillo', symbol: 'U.Brillo' },
  TYPE_G: { code: 'g', name: 'unit.gram', symbol: 'g' },
  TYPE_KG: { code: 'kg', name: 'unit.kilogram', symbol: 'Kg' },
  TYPE_MG: { code: 'mg', name: 'unit.miligram', symbol: 'mg' },
  TYPE_ML: { code: 'ml', name: 'unit.mililiter', symbol: 'ml' },
  TYPE_L: { code: 'l', name: 'unit.liter', symbol: 'L' },
  TYPE_ML_MIN: {
    code: 'ml/min',
    name: 'unit.mililiter_per_minute',
    symbol: 'ml/min'
  },
  TYPE_NS_CM: {
    code: 'ns/cm',
    name: 'unit.microsiemens_per_centimeter',
    symbol: 'µS/cm'
  },
  TYPE_PROCEQ: { code: 'proceq', name: 'unit.proceq', symbol: 'ºProceq' },
  TYPE_LUX: { code: 'lux', name: 'unit.lux', symbol: 'Lux' },
  TYPE_V: { code: 'v', name: 'unit.voltage', symbol: 'V' },
  TYPE_MILIV: { code: 'miliv', name: 'unit.milivolt', symbol: 'mV' },
  TYPE_DAN_CM: {
    code: 'dan*cm',
    name: 'unit.decanewton_centimeter',
    symbol: 'daN.cm'
  },
  TYPE_N_M: { code: 'n*m', name: 'unit.newton_meter', symbol: 'N.m' },
  TYPE_KGF_M: {
    code: 'kgf*m',
    name: 'unit.kilogram_force_meter',
    symbol: 'Kgf.m'
  },
  TYPE_N_CM: {
    code: 'n*cm',
    name: 'unit.newton_centimeter',
    symbol: 'N.cm'
  },
  TYPE_GF_M: {
    code: 'gf*m',
    name: 'unit.gram_force_meter',
    symbol: 'gf.m'
  },
  TYPE_LBF_IN: {
    code: 'lbf*in',
    name: 'unit.pound_force_inch',
    symbol: 'lbf.in'
  },
  TYPE_LBF_FT: {
    code: 'lbf*ft',
    name: 'unit.pound_force_foot',
    symbol: 'lbf.ft'
  },
  TYPE_KN_M: { code: 'kn*m', name: 'unit.kilonewton_meter', symbol: 'KN.m' },
  TYPE_GF_CM: {
    code: 'gf*cm',
    name: 'unit.gram_force_centimeter',
    symbol: 'gf.cm'
  },
  TYPE_KGF_CM: {
    code: 'kgf*cm',
    name: 'unit.kilogram_force_centimeter',
    symbol: 'Kgf.cm'
  },
  TYPE_PH: { code: 'ph', name: 'unit.picohenry', symbol: 'pH' },
  TYPE_BAR: { code: 'bar', name: 'unit.bar', symbol: 'Bar' },
  TYPE_KPA: { code: 'kpa', name: 'unit.kilopascal', symbol: 'Kpa' },
  TYPE_KG_CM2: {
    code: 'kg/cm2',
    name: 'unit.kilogram_per_squarecentimeter',
    symbol: 'Kg/cm²'
  },
  TYPE_PSI: {
    code: 'psi',
    name: 'unit.pountforce_per_squareinch',
    symbol: 'PSI'
  },
  TYPE_MBAR: { code: 'mbar', name: 'unit.milibar', symbol: 'mBar' },
  TYPE_N_DIV_M2: {
    code: 'n/m2',
    name: 'unit.newton_per_squaremeter',
    symbol: 'N/m²'
  },
  TYPE_KN_DIV_M2: {
    code: 'kn/m2',
    name: 'unit.kilonewton_per_squaremeter',
    symbol: 'KN/m²'
  },
  TYPE_N_DIV_CM2: {
    code: 'n/cm2',
    name: 'unit.newton_per_squarecentimeter',
    symbol: 'N/cm²'
  },
  TYPE_KG_DIV_M2: {
    code: 'kg/m2',
    name: 'unit.kilogram_per_squaremeter',
    symbol: 'Kg/m²'
  },
  TYPE_TU: { code: 'tu', name: 'unit.taber_unit', symbol: 'T.U' },
  TYPE_C: { code: 'c', name: 'unit.celsius_degree', symbol: 'ºC' },
  TYPE_F: { code: 'f', name: 'unit.fahrennheit_degree', symbol: 'ºF' },
  TYPE_CP: { code: 'cp', name: 'unit.centipoise_unit', symbol: 'cP' },
  TYPE_J: { code: 'j', name: 'unit.joule', symbol: 'J' },
  TYPE_J_M2: {
    code: 'J/m2',
    name: 'unit.joule_per_squaremeter',
    symbol: 'J/m²'
  },
  TYPE_FTLB_IN2: {
    code: 'ftlb/in2',
    name: 'unit.footpound_per_squareinch',
    symbol: 'ftlb/in²'
  },
  TYPE_MFTLB_IN2: {
    code: 'mftlb/in2',
    name: 'unit.meterfootpound_per_squareinch',
    symbol: 'mftlb/in²'
  },
  TYPE_MJ_IN2: {
    code: 'mj/in2',
    name: 'unit.megajoule_per_squareinch',
    symbol: 'mJ/in²'
  },
  TYPE_KG_CM_IN2: {
    code: 'kg*cm/in2',
    name: 'unit.kilogram_centimeter_per_squareinch',
    symbol: 'Kg.cm/in²'
  },
  TYPE_FTLB: {
    code: 'ftlb',
    name: 'unit.footpound',
    symbol: 'ftlb'
  },
  TYPE_FOLD: { code: 'fold', name: 'unit.fold', symbol: 'pliegues' },
  TYPE_RPM: { code: 'rpm', name: 'unit.revolution_per_minute', symbol: 'rpm' },
  TYPE_HZ: { code: 'hz', name: 'unit.hertz', symbol: 'Hz' },
  TYPE_RAD_DIV_S: {
    code: 'rad/s',
    name: 'unit.radian_per_second',
    symbol: 'rad/s'
  },
  TYPE_SHORE: { code: 'shore', name: 'unit.shore', symbol: 'ºShore' },
  TYPE_PERCENTAGE: {
    code: 'percentage',
    name: 'unit.percentage',
    symbol: '%'
  },
  TYPE_DEGREE: { code: 'degree', name: 'unit.degree', symbol: 'º' },
  TYPE_MV: { code: 'mv', name: 'unit.milivolt', symbol: 'mV' },
  TYPE_LB: { code: 'lb', name: 'unit.pound', symbol: 'lb' },
  TYPE_G_DIV_M2: {
    code: 'g/m2',
    name: 'unit.gram_per_squaremeter',
    symbol: 'g/m²'
  },
  TYPE_G_DIV_CM2: {
    code: 'g/cm2',
    name: 'unit.gram_per_squarecentimeter',
    symbol: 'g/cm²'
  },
  TYPE_DEGREE_DIV_S: {
    code: 'º/s',
    name: 'unit.degree_per_second',
    symbol: 'º/s'
  },
  TYPE_DEGREE_DIV_MIN: {
    code: 'º/min',
    name: 'unit.degree_per_minute',
    symbol: 'º/min'
  }
};

export function getByCode(
  code: string
): (typeof UnitType)[keyof typeof UnitType] {
  return Object.values(UnitType).filter((item) => item.code === code)[0];
}

export default UnitType;
